.text_div {
  width: 100%;
}
.text_div > h1 {
  font-family: "Be Viettnam Pro Bold";

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
}
.text_div > p {
  color: #000;
  font-family: "Be Viettnam Pro Light";
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
}
.text_div > p > ul > li > h1 {
  font-family: "Be Viettnam Pro Bold";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
}

.newtitle {
  width: 100% !important;
}

.sub_title_heading {
  padding: 1rem 0rem;
}
.Modalbody {
  height: 80vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
