.sec5_img_box > img {
  width: 100% !important;
  aspect-ratio: 3/2 !important;
  object-fit: contain !important;
}
.sec5_img_box {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  /* background-color: aqua; */
}
.submain_sec5 {
  width: 85%;
  height: 95%;
  display: flex;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  flex-direction: column;
  padding: 0rem 1rem;
}
.submain_sec5 > h1 {
  color: #000;
  font-family: "DM Sherif Regular";
  font-size: 49px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-top: 0px;
  margin-bottom: 1.5rem;
  margin-left: 1rem;
}

@media screen and (max-width: 820px) {
  .submain_sec5 {
    flex-direction: column;
  }
  .sec5_img_box > img {
    width: 70%;
  }
}
@media screen and (max-width: 540px) {
  .sec5_img_box > img {
    width: 75%;
  }
}
