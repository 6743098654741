@import url("https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;600;700&display=swap");
.section_one {
  width: 100%;
  padding-top: 101px;
  height: 100vh;
  background-color: #e30a17;
  background-image: url("../../../../assets/Sec_1.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: grid;
  place-items: center;
}
.submain_div {
  width: 90%;
  height: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.inner_text_div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.inner_text_div > h3 {
  margin: 0px;
  font-family: "Be Viettnam Pro Bold";
  color: white;
  text-align: center;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -2.04px;
}
.inner_text_div > span {
  margin: 0px;
  font-family: "Be Viettnam  Pro Thin";
  color: #fff;
  text-align: center;
  font-size: 64px;
  font-style: normal;
  letter-spacing: -2.56px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inner_text_div > span > h1 {
  margin: 0px;
  font-family: "Be Viettnam pro Bold";
  color: #fff;
  text-align: center;
  font-size: 64px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: -2.56px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inner_text_div > h1 > span {
  margin-bottom: 20px;
}
.inner_text_div > h4 {
  margin: 0px;
  font-family: "DM Sherif Regular";
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media screen and (max-width: 540px) {
  .inner_text_div > h3 {
    font-size: 17px;
  }

  .inner_text_div > h1 {
    font-size: 30px;
  }
  .inner_text_div > span {
    margin: 0px;
    font-family: "Be Viettnam  Pro Thin";
    color: #fff;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    letter-spacing: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .inner_text_div > span > h1 {
    margin: 0px;
    font-family: "Be Viettnam pro Bold";
    color: #fff;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .inner_text_div > h1 > span {
    margin-bottom: 10px;
    font-weight: 200;
    font-family: "Be Viettnam Pro Thin";
  }

  .inner_text_div > h4 {
    font-size: 13px;
  }
  .section_one {
    width: 100%;
    height: 50vh;
    background-size: 190% 100%;
    background-position: center;
  }
}
@media screen and (max-width: 820px) {
  .section_one {
    height: 70vh;
    background-size: cover;
    background-position: center;
    display: grid;
    place-items: center;
  }
}
