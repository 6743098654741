.sec5_img_box > img {
  width: 87%;
}
.sec5_img_box {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  /* background-color: aqua; */
}
.submain_sec5 {
  width: 85%;
  height: 95%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0rem 1rem;
}

@media screen and (max-width: 820px) {
  .submain_sec5 {
    flex-direction: column;
  }
  .sec5_img_box > img {
    width: 70%;
  }
}
@media screen and (max-width: 540px) {
  .sec5_img_box > img {
    width: 75%;
  }
}
