@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap");

.Section8 {
  width: 100%;
  height: 100%;
  background-color: #fff0f1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 0rem;
}
.section8_text_div {
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.heading_text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.heading_text > h1 {
  color: #000;
  text-align: center;
  font-family: "Bree Serif", serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 54px;
  margin-bottom: 15px;
}
.heading_text > h1 > span {
  color: #000;
  font-weight: 400;
}

.heading_text > p {
  color: #000;
  text-align: center;
  font-family: "Be Viettnam Pro SemiBold";
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}
.Section8_img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 100%;
  padding: 2rem 0rem;
}
.Section8_img > img {
  width: 80%;
}
.bottom_text_div {
  width: 80%;
}
.bottom_text_div > p {
  color: #000;
  text-align: center;
  font-family: "Be Viettnam Pro Light";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
  margin-top: 1rem;
}

@media screen and (max-width: 820px) {
  .heading_text > h1 {
    font-size: 33px;
    line-height: 35px;
    margin-bottom: 10px;
  }
  .heading_text > p {
    font-size: 18px;
    line-height: 25px;
  }
  .Section8_img {
    padding: 1rem 0rem;
  }
  .Section8_img > img {
    width: 90%;
  }
  .bottom_text_div > p {
    font-size: 14px;
    font-weight: 300;
    line-height: 26px;
  }
  .store_img > img {
    width: 45%;
  }
}
@media screen and (max-width: 540px) {
  .section8_text_div {
    width: 95%;
  }
  .Section8 {
    padding: 2rem 0rem;
  }
  .heading_text > h1 {
    font-size: 22px;
    line-height: 35px;
    margin-bottom: 10px;
  }
  .heading_text > p {
    font-size: 13px;
    line-height: 25px;
  }
  .store_img > img {
    width: 25%;
  }
  .Section8_img > img {
    width: 100%;
  }
  .bottom_text_div > p {
    font-size: 10px;
    font-weight: 300;
    line-height: 26px;
  }
}
