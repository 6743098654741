.main_div {
  width: 100%;
  height: 100vh;
  background: url("../../assets/bg2.png");
  padding: 3rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.contacnt_div {
  width: 80%;
  height: 90%;
  overflow-y: scroll;
}
.head_text {
  color: white;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  padding-bottom: 15px;
  border-bottom: 1px solid #fff;
}
.head_text_french {
  color: white;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  padding-bottom: 15px;
  border-bottom: 1px solid #fff;
}
.sub_title_heading > h6 {
  color: #f0ffff;
  font-family: "Be Viettnam Pro SemiBold";
  font-size: 20px;
}

.text_div > h1 {
  font-family: "Be Viettnam Pro Bold";
  color: #f0ffff;
  font-size: 18px;
}
.text_div > p {
  font-family: "Be Viettnam Pro Medium";
  color: #f0ffff;
  font-size: 14px;
}
/* .arrow {
  background-color: #f0ffff;
  color: #e84444;
  width: 70px;
  height: 50px;
  position: absolute;
  left: 10%;
  top: 5%;
} */
.contacnt_div::-webkit-scrollbar {
  display: none !important;
}
.arrow {
  position: absolute;
  left: 10%;
  top: 3%;
}
/* .arrow > p:hover {
  background-color: #fff;
  color: #e84343;
  border-radius: 30%;
  /* padding: 10px 1rem; */
/* } */

/* CSS */
.button-27 {
  appearance: none;
  background-color: #00000000;
  border: 2px solid #1a1a1a00;
  border-radius: 15px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 30px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 12px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform;
}

.button-27:disabled {
  pointer-events: none;
}

.button-27:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
  border: 2px solid #fff;
}

.button-27:active {
  box-shadow: none;
  transform: translateY(0);
}
