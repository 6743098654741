@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap");

.Newsection {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 0rem;
}
.submain_Newsection_div {
  width: 80%;
  height: 100%;
  display: grid;
  grid-template-columns: 40% 50%;
  align-items: center;
  justify-content: center;
  gap: 4rem;
}
.Newsection_img_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: aqua; */
}
.Newsection_text_div {
  width: 100%;
  height: 100%;
}
.Newsection_img_div > img {
  width: 100%;
  aspect-ratio: 3/3;
  object-fit: contain;
  filter: drop-shadow(0 0 0.75rem #666666);
}

.Newsection_text_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.Newsection_text_div > h1 {
  color: #000000;
  font-family: "Bree Serif", serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 54px; /* 128.571% */
}

.Newsection_text_div > p {
  color: #000000;
  font-family: "Be Viettnam Pro Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px; /* 177.778% */
}
.Newsection_store_div > p {
  color: #000000;
  font-family: "Be Viettnam Pro SemiBold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media screen and (max-width: 820px) {
  .submain_sec6_div {
    display: flex;
    flex-direction: column;
  }
  .Newsection_text_div > h1 {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 540px) {
  .section6_img_div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .section6_img_div > img {
    width: 90%;
    aspect-ratio: 3/3;
    object-fit: contain;
  }
  .submain_sec6_div {
    width: 80%;
    height: 100%;
    /* display: flex;
    flex-direction: column-reverse;
    gap: 2rem; */
  }
  .Newsection_text_div > h1 {
    font-size: 24px;
    line-height: 32px;
  }
  .Newsection_text_div > p {
    font-size: 15px;
    line-height: 25px;
  }
}
