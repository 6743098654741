.Section3 {
  width: 100%;
  height: 100%;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 4rem 0rem;
}
.sub_sec3 {
  width: 90%;
  height: 90%;
  /* background-color: #e13232; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
}
.sec3img_div {
  /* width: 70%; */

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  place-items: center;
  gap: 1rem !important;
}
.sec3text_div {
  width: 35%;
  height: 90%;
  /* background-color: cornflowerblue; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.details_img_box {
  border-radius: 10px;
  box-shadow: rgba(255, 255, 255, 0.16) 0px 1px 4px;
  /* border: 1px solid #dcdcdc; */
  background: #2a2a2a;
  width: 170px;
  height: 215px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.5rem;
}
.details_img_box > img {
  width: 100%;
}
.nametext_div {
  width: 90%;
}
.nametext_div > h4 {
  margin: 0px;
  color: #ffffff;
  font-family: "Be Viettnam Pro Medium";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.sec3text_div > h1 {
  color: #ffffff;
  font-family: "DM Sherif Regular";
  font-size: 45px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  margin-bottom: 1.5rem;
}
.sec3text_div > p {
  width: 90%;
  color: #ffffff;
  font-family: "Be Viettnam Pro Light";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
}
.sec3text_div > h4 {
  color: #fff;
  font-family: "Be Viettnam Pro Medium";
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 10px;
}
.PRE_btn {
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 48px;
  background-color: #e13232;
  color: #fff;
  padding: 12px 29px;
  font-family: "Be Viettnam Pro SemiBold";
  margin: 15px 0px;
  text-decoration: none;
}
.store_div > p {
  color: #ffffff;
  font-family: "Be Viettnam Pro SemiBold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.store_img {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

@media screen and (max-width: 820px) {
  .Section3 {
    height: 100%;
    padding: 2rem 0rem;
  }
  .sub_sec3 {
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .sec3img_div {
    width: 80%;
    gap: 10px !important;
  }
  .sec3text_div {
    width: 80%;
  }
  .sec3text_div > p {
    width: 100%;
  }
  .sec3text_div > h4 {
    font-size: 18px;
  }
}

@media screen and (max-width: 1030px) {
  .Section3 {
    height: 100%;
    padding: 2rem 0rem;
  }
  .sub_sec3 {
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .sec3img_div {
    width: 95%;
  }
  .sec3text_div {
    width: 80%;
  }
  .sec3text_div > p {
    width: 100%;
  }
  .sec3text_div > h4 {
    font-size: 22px;
  }
}

@media screen and (max-width: 540px) {
  .Section3 {
    height: 100%;
    padding: 2rem 0rem;
  }
  .sub_sec3 {
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .details_img_box {
    border-radius: 5px;
    width: 80px;
    height: 115px;
  }
  .sec3img_div {
    width: 95%;
  }

  .nametext_div > h4 {
    font-size: 7px;
  }
  .store_img {
    align-items: flex-start;
    justify-content: flex-start;
    /* flex-direction: column; */
  }
  .store_img > img {
    width: 30%;
  }
  .sec3text_div {
    width: 80%;
  }
  .sec3text_div > h1 {
    font-size: 28px;
  }
  .sec3text_div > p {
    width: 100%;
    font-size: 14px;
  }
  .store_div > p {
    font-size: 8px;
  }
  .sec3text_div > h4 {
    font-size: 15px;
  }
}
