@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap");

.Section7 {
  width: 100%;
  height: 100%;
  background-color: #e30a17;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 0rem;
}
.submain_sec7_div {
  width: 80%;
  height: 100%;
  display: grid;
  grid-template-columns: 50% 35%;

  align-items: center;
  justify-content: center;
  gap: 4rem;
}

.section7_img_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section7_img_div > img {
  width: 100%;
}
.section7_text_div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.section7_text_div > h1 {
  color: #fff;
  font-family: "Bree Serif", serif;
  font-size: 42px;
  font-style: normal;
  line-height: 54px;
}

.section7_text_div > p {
  color: #fff;
  font-family: "Be Viettnam Pro Light";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
}
@media screen and (max-width: 540px) {
  .submain_sec7_div {
    display: flex;
    flex-direction: column;
  }
  .Section7 {
    width: 100%;
    height: 100%;
    background-color: #e30a17;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4rem 0rem;
  }
  .section7_text_div > h1 {
    font-size: 24px;
    line-height: 32px;
  }
  .section7_text_div > p {
    font-size: 12px;
    line-height: 22px;
  }
}
