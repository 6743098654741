* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-sec {
  width: 100%;
  height: 100vh;
  background-color: #e03d3d;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 2rem;
}

.text img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  filter: drop-shadow(0px 8px 8px white);
}

.text h1 {
  color: white;
  font-family: "Bree Serif", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 46px;
  line-height: 58px;
}

.text p {
  color: white;
  font-family: "Bree Serif", serif;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 25px;
}

.button_back {
  background-color: whitesmoke;
  color: #e30a17;
  padding: 10px 30px;
  border: none;
  border-radius: 20px;
  font-size: 17px;
  font-family: "Be Viettnam Pro Medium";
  font-style: normal;
  font-weight: 300;
  filter: drop-shadow(0px 4px 4px white);
  text-decoration: none;
}

.button_back:hover {
  transition: all 1s ease-in;
  background-color: #e30a17;
  color: white;
  border: 1px solid white;
  font-family: "Be Viettnam Pro Medium";
  font-style: normal;
  font-weight: 300;
  filter: drop-shadow(0px 20px 8px #000000f4);
}
