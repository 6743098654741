.main_footer {
  background-color: black;
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
footer {
  width: 80%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #999999;
}
.text_div_main {
  width: 100%;
  height: 100%;
  /* padding-left: 1rem; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.sub_text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}
.sub_text > .Flnk {
  cursor: pointer;
  color: #fff;
  font-family: "Be Viettnam Pro Bold";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

.icon_div {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  /* padding-right: 2rem; */
}
.lasttext {
  width: 80%;
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0px;
}

.lasttext > p {
  color: #fff;
  font-family: "Be Viettnam Pro Thin";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
}

@media screen and (max-width: 1030px) {
  .sub_text > .Flnk {
    font-size: 12px;
  }
  .text_div_main {
    width: 60%;
  }
}
@media screen and (max-width: 820px) {
  footer {
    justify-content: center;
    flex-direction: column;
    height: 35%;
  }
  .text_div_main {
    width: 100%;
    align-items: center;
  }
}
@media screen and (max-width: 540px) {
  .text_div_main {
    padding-left: 0px;
  }
  .sub_text {
    width: 80%;
    align-items: center;
    justify-content: center;
    /* flex-wrap: wrap; */
    gap: 5px;
  }
  .sub_text > .Flnk {
    display: flex;
    font-size: 7.5px;
    font-family: "Be Viettnam Pro Medium";
    flex-wrap: wrap;
    text-align: center;
  }
  footer {
    width: 100%;
  }
  .lasttext > p {
    font-size: 9px;
    line-height: 15px;
  }

  .icon_div {
    width: 60%;
    justify-content: center;
    padding-right: 0px;
  }
}
