.Section4 {
  width: 100%;
  height: 100%;
  background-image: url("../../../../assets/sec4_bg.png");
  background-position: center;
  background-size: 100% 100%;
  background-color: #e30a17;
  display: grid;
  place-items: center;
  padding: 4rem 0rem;
}
.sub_sec4 {
  width: 90%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
}
.sec4_text_main_div {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.sec4_text_main_div > h1 {
  color: #fff;
  text-align: center;
  font-family: "DM Sherif Regular";
  font-size: 49px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  padding: 1.5rem 0rem;
}
.sec4_text_main_div > h3 {
  color: #fff;
  text-align: center;
  font-family: "Be Viettnam Pro SemiBold";
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  padding: 1.5rem 0rem;
}
.sec4_text_main_div > p {
  width: 68%;
  color: #fff;
  text-align: center;
  font-family: "Be Viettnam Pro Light";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
}

.sec4_img_main_div {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.firstimg_div {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.firstimg_div > img {
  width: 100%;
}
.sub_first_div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.sub_first_div > img {
  width: 49.3%;
  aspect-ratio: 3/3;
  object-fit: cover;
}
.PRE_btn1 {
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 48px;
  background-color: #000000;
  color: #fff;
  padding: 12px 29px;
  font-family: "Be Viettnam Pro SemiBold";
  text-decoration: none;
}
@media screen and (max-width: 1030px) {
  .sec4_text_main_div > h1 {
    font-size: 35px;
  }
  .sec4_text_main_div > h3 {
    font-size: 22px;
    margin-bottom: 3px;
  }
  .sec4_text_main_div > p {
    width: 90%;
    font-size: 14px;
  }
  .sec4_img_main_div {
    flex-direction: column;
    /* display: none; */
  }
  .firstimg_div > img {
    width: 100%;
  }
  .sub_first_div {
    width: 100%;
  }
  .sub_first_div > img {
    width: 49.4%;
  }
}
@media screen and (max-width: 820px) {
  .sec4_text_main_div > h1 {
    font-size: 35px;
  }
  .sec4_text_main_div > h3 {
    font-size: 22px;
    margin-bottom: 3px;
  }
  .sec4_text_main_div > p {
    width: 90%;
    font-size: 14px;
  }
  .sec4_img_main_div {
    flex-direction: column;
    /* display: none; */
  }
  .firstimg_div > img {
    width: 100%;
  }
  .sub_first_div {
    width: 100%;
  }
  .sub_first_div > img {
    width: 49.4%;
  }
}
@media screen and (max-width: 540px) {
  .sec4_text_main_div > h1 {
    font-size: 28px;
  }
  .PRE_btn1 {
    font-size: 12px;
  }
  .sec4_text_main_div > h3 {
    font-size: 20px;
    margin-bottom: 3px;
  }
  .sec4_text_main_div > p {
    width: 90%;
    font-size: 14px;
    line-height: 25px;
  }
  .sec4_img_main_div {
    flex-direction: column;
    /* display: none; */
  }
  .firstimg_div > img {
    width: 100%;
  }
  .sub_first_div {
    width: 100%;
  }
  .sub_first_div > img {
    width: 49%;
  }
}
