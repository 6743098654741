@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap");

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.Contactus {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0rem;
}
.Contactus_sub {
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.Contactus_sub > h1 {
  font-family: "Bree Serif", serif;
  color: #000;
  text-align: center;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: 54px;
}
.inner_div_contact {
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.form_div {
  width: 100%;
  height: max-content;

  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.img_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
form {
  width: 90%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.submain_form_box {
  display: flex;
  flex-direction: column;

  gap: 2rem;
  padding: 2rem 0rem;
}
.fname_main_ {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.name_div,
.bus_main,
.msg_main {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.name_div,
.bus_main,
.msg_main > label {
  color: #000;
  font-family: "Be Viettnam Pro Medium";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
}
.input_div {
  width: 253px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #e6e6e6;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0px 5px;
}
.input_div_ {
  width: 253px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #e6e6e6;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  gap: 10px;
  padding: 0px 5px;
}
.input_div > input {
  width: 90%;
  height: 90%;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 15px;
  font-family: "Be Viettnam Pro Medium";
  font-size: 14px;
}
.input_div > .PhoneInput {
  width: 90%;
  height: 90%;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 15px;
  font-family: "Be Viettnam Pro Medium";
  font-size: 14px;
}
.PhoneInputInput {
  flex: 1 1;
  min-width: 0;
  border: none;
  outline: none;
}

.errors_msg_p {
  position: absolute;
  top: 4.7rem;
  color: red;
  font-size: 12px;
  font-family: "Be Viettnam Pro Medium";
}
.bis_input {
  width: 538px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #e6e6e6;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0px 5px;
}
.bis_input > input {
  width: 90%;
  height: 90%;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 15px;
  font-family: "Be Viettnam Pro Medium";
  font-size: 14px;
}
.msg_input {
  width: 531px;
  height: 135px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #e6e6e6;
  background: #fff;
  padding: 10px;
}
.msg_input > textarea {
  resize: none;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-family: "Be Viettnam Pro Medium";
  font-size: 14px;
}
.btn_submit {
  width: 150px;
  height: 40px;
  border-radius: 50px;
  background: #e30a17;
  color: white;
  font-family: "Be Viettnam Pro Bold";
  font-size: 14px;
  outline: none;
  border: none;
  cursor: pointer;
  color: #fff;
  font-style: normal;
  line-height: normal;
}

@media screen and (max-width: 1040px) {
  .inner_div_contact {
    flex-direction: column;
  }
}
@media screen and (max-width: 820px) {
  .inner_div_contact {
    flex-direction: column;
  }
  .Contactus {
    padding: 2rem 0rem;
  }
}
@media screen and (max-width: 540px) {
  .inner_div_contact {
    flex-direction: column;
  }
  .submain_form_box {
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 2rem 0rem;
  }
  .fname_main_ {
    flex-direction: column;
  }
  .bis_input {
    width: 300px;
  }
  .msg_input {
    width: 300px;
  }
  .img_div {
    width: 90%;
  }
  .img_div > img {
    width: 90%;
  }
}
