.section2 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 0rem;
}
.submain_sec2 {
  width: 85%;
  height: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 0rem 1rem;
}
.sec2_textbox {
  width: 50%;
  height: 100%;
  padding: 0rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.sec2_textbox > p {
  color: #000;
  font-family: "Be Viettnam Pro Light";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
}
.sec2_textbox > h1 {
  color: #000;
  font-family: "DM Sherif Regular";
  font-size: 49px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-top: 0px;
  margin-bottom: 1.5rem;
}
.sec2_img_box > img {
  width: 90%;
}
@media screen and (max-width: 1030px) {
  .section2 {
    height: 100%;
  }

  .submain_sec2 {
    width: 80%;
    height: 100%;
    align-items: center;
    justify-content: center;
    /* flex-direction: column; */
    gap: 2rem;
  }
  .sec2_textbox {
    width: 90%;
    height: 100%;
    padding: 0rem 1rem;
  }
  .sec2_textbox > p {
    font-size: 12px;
    line-height: 22px;
  }
  .sec2_textbox > h1 {
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  .sec2_img_box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sec2_img_box > img {
    width: 80%;
  }
}
@media screen and (max-width: 820px) {
  .section2 {
    height: 100%;
  }

  .submain_sec2 {
    width: 80%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
  }
  .sec2_textbox {
    width: 90%;
    height: 100%;
    padding: 0rem 1rem;
  }
  .sec2_textbox > p {
    font-size: 12px;
    line-height: 22px;
  }
  .sec2_textbox > h1 {
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  .sec2_img_box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sec2_img_box > img {
    width: 80%;
  }
}
@media screen and (max-width: 540px) {
  .section2 {
    height: 100%;
  }

  .submain_sec2 {
    width: 80%;
    height: 100%;

    flex-direction: column;
    gap: 1rem;
  }
  .sec2_textbox {
    width: 100%;
    height: 100%;
    padding: 0rem 1rem;
  }
  .sec2_textbox > p {
    font-size: 12px;
    line-height: 22px;
  }
  .sec2_textbox > h1 {
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  .sec2_img_box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sec2_img_box > img {
    width: 80%;
  }
}
